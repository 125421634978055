import useOnClickOutside from 'hooks/use-onclick-outside';
import React, { useEffect, useRef, useState } from 'react';
import { NewButtonText } from 'components/shared/button';
import { Divider, HelpMenuContainer, HelpMenuDropdown, HelpMenuItem, HelpIcon, StyledIcon, StyledLink } from './HelpMenu.style';
import { helpMenuGqls } from './helpMenu.gqls';
import { client } from 'app/apollo';

const HelpMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));

  const fetchEnvVar = async () => {
    return await client.query({ query: helpMenuGqls.queries.getEnv, fetchPolicy: 'network-only' });
  };
  const [envVariables, setEnvVariables] = useState({
    marketId: '',
    mcdEnv: '',
    USERGUIDE_PDF_SUFFIX: '',
  });
  useEffect(() => {
    const data = fetchEnvVar();
    data.then((res) => {
      const {
        getEnvironments: { marketId, mcdEnv, USERGUIDE_PDF_SUFFIX },
      } = res.data || { getEnvironments: {} };
      setEnvVariables({
        marketId,
        mcdEnv,
        USERGUIDE_PDF_SUFFIX,
      });
    });
  }, []);
  
  return (
    <HelpMenuContainer  ref={ref} data-automation-id="help-container">
      <NewButtonText aria-label='Help' onClick={() => setIsOpen(!isOpen)} data-title="Help">
        <HelpIcon name="help" />
      </NewButtonText>
      {isOpen && (
        <HelpMenuDropdown>
          <HelpMenuItem>
            <StyledLink href={`https://www.user-guide.${envVariables.marketId}.${envVariables.mcdEnv}.vce.mcd.com`} target="_blank" rel="noreferrer">
              <StyledIcon  name="export" />
              Go to Online Help
            </StyledLink>
          </HelpMenuItem>
          <Divider />
          <HelpMenuItem>
            <StyledLink
              href={`https://www.user-guide.${envVariables.marketId}.${envVariables.mcdEnv}.vce.mcd.com/${envVariables.USERGUIDE_PDF_SUFFIX}`}
              target="_blank"
              download
              rel="noreferrer"
            >
              <StyledIcon name="download" />
              Download User Guide
            </StyledLink>
          </HelpMenuItem>
        </HelpMenuDropdown>
      )}
    </HelpMenuContainer>
  );
};

export default HelpMenu;
