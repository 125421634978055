import React from 'react';
import { FieldValues, UseFormGetValues } from 'react-hook-form';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { ProductSetType } from 'utils/types';
import { ProductBagInfo } from 'utils/types/products';
import { StyledButtonText, StyledEditIcon, StyledViewIcon } from '../Template.style';
import { StyledButtonProductSelectionText} from '../../../OfferForm.style';
import Tooltip from 'components/shared/tooltip/Tooltip';


const getProductsAction = (
  disabled: boolean,
  buyGetProducts: ProductBagInfo,
  getValues?: UseFormGetValues<FieldValues>,
  textLabel?: string,
  productsKey?: string,
  mapProducts?: any,
  onProductSelection?: any,
  isOptional = false,
  setType?: ProductSetType,
  familyGroup?: string,
  offerID?: number,
) => {
  if (buyGetProducts) {
    if (!disabled) {
      return (
        <StyledButtonProductSelectionText
          key={productsKey}
          disabled={disabled}
          onClick={() => {
            onProductSelection(
              productsKey,
              (data: any) => {
                store.dispatch(
                  openModal({
                    modal: Modals.OfferModal,
                    data: { templateProducts: mapProducts(data) },
                  }),
                );
              },
              isOptional,
              setType,
              familyGroup,
            );
          }}
          data-title="Edit"
        >
          <StyledEditIcon name="edit" />
        </StyledButtonProductSelectionText>
      );
    }
    const onProductPreviewClick = () => {
      store.dispatch(
        openModal({
          modal: Modals.ProductViewModal,
          props: {
            mode: 'view',
            buyGetProducts: buyGetProducts,
            offerID: offerID
          },
        }),
      );
    };
    return (
      <>
       
        <StyledButtonText data-tip onClick={onProductPreviewClick} data-title="View">
          <StyledViewIcon name="blueEye" />
        </StyledButtonText>
      </>
    );
  }
  return (
    <StyledButtonProductSelectionText
      disabled={disabled}
      onClick={() =>
        onProductSelection(
          productsKey,
          (data: any) => {
            store.dispatch(
              openModal({
                modal: Modals.OfferModal,
                data: { templateProducts: mapProducts(data) },
              }),
            );
          },
          isOptional,
          setType,
          familyGroup,
        )
      }
    >
      {textLabel}
    </StyledButtonProductSelectionText>
  );
};

export default getProductsAction;
