import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DiscountType } from 'utils/types/offers';
import { ButtonText } from 'components/shared/button';
import { calcProductsHint, getUniqueProducts } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { EnterValueWrapper, ProductPicker, StyledBuySelectbox, StyledForSelectbox, StyledLabel } from '../Template.style';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import { AddRowWrapper, StyledButtonText, StyledIcon } from './ProductCombo.style';
import { Currency } from '../shared/Currency/Currency';
import getProductsAction from '../shared/ProductsActionCondition';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, LineWrapperSpaceBetween, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import { getIsControlWithError } from 'utils/form';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';


const BuyAndSection = ({
  getLabel,
  index,
  disabled,
  control,
  templates,
  buySelectboxWidth,
  trigger,
  getValues,
  onProductSelection,
  offerID,
  register
} : any) => {
  return (
    <><LineWrapper gap={8}>
      <StyledLabel>{getLabel(index)}</StyledLabel>
      <StyledBuySelectbox
        disabled={disabled}
        control={control}
        name={`versions.0.templateValues.offerTemplates.${index}.buy` as const}
        validation={{
          min: 1,
          max: 15,
          required: true,
        }}
        items={Array.from(
          { length: 15 },
          (x, i) => ({
            id: (i + 1).toString(),
            name: (i + 1).toString(),
          } as any)
        )}
        defaultValue="1"
        initialSelectedItems={templates[index]?.buy
          ? [typeof templates[index]?.buy === 'object' ? templates[index]?.buy.id : templates[index]?.buy]
          : ['1']}
        selectWidth={buySelectboxWidth}
        onChange={() => trigger(`versions.0.templateValues.offerTemplates.${index}.discountValue`)}
        version='offer-form' />
    </LineWrapper><>
        {templates && (
          <ProductPicker>
            <ProductLine>
              {calcProductsHint(templates[index]?.buyProducts, 'productCombo') ?? ''}
            </ProductLine>
            {getProductsAction(
              disabled,
              templates[index]?.buyProducts,
              getValues,
              'Select Products',
              `offerTemplates.${index}.buyProducts`,
              (data: any) => ({ productCombo: { [index]: data } }),
              onProductSelection,
              null,
              null,
              null,
              offerID
            )}
          </ProductPicker>
        )}
      </><input
        type="hidden"
        {...register(`versions.0.templateValues.offerTemplates.${index}.buyProducts` as const, {
          value: templates ? templates[index]?.buyProducts : undefined,
          required: true,
          shouldUnregister: true,
        })} /></>
  )
}

const EnterValueSection = ({
  disabled,
  shouldDisplayCurrency,
  index,
  discountTypeKey,
  templateDiscountType,
  register,
  validationMin,
  validationMax,
  getValues,
  config,
  validationReturn,
  errors,
  setValue,
  setIsAmountChanged
} : any) => {
  return (
    <EnterValueWrapper disabled={disabled} width={69.25} errors={getIsControlWithError(`versions.0.templateValues.offerTemplates.${index}.discountValue`, errors)}>
          {shouldDisplayCurrency && <Currency />}
                  <OfferTemplateTextField
                  key={`versions.0.templateValues.offerTemplates.${index}.discountValue-${discountTypeKey}` as const}
                  disabled={templateDiscountType === DiscountType.Percent || templateDiscountType === DiscountType.Free || disabled}
                  type={templateDiscountType}
                  register={register}
                  name={`versions.0.templateValues.offerTemplates.${index}.discountValue` as const}
                  placeholder="0"
                  validation={{
                    required: true,
                    min: validationMin,
                    max: validationMax,
                    pattern: /^\d+(?:\.\d{1,2})?$/,
                    validate: (v: string) => {
                      const buy = getValues(`versions.0.templateValues.offerTemplates.${index}.buy`);
                      const isNonExactPenny = Math.ceil(+(Number(v) * 100).toFixed(2)) % Number(buy) !== 0;
                      if (templateDiscountType === DiscountType.MoneyOff && isNonExactPenny) {
                        return `${config.currency} off divided by number of items does not result in exact penny value for selected option`;
                      }
                      if (templateDiscountType === DiscountType.FixedPrice && isNonExactPenny) {
                        return 'Fixed Price total divided by number of items does not result in exact penny value for selected option';
                      }
                      return validationReturn(v)
                    },
                  }}
                  errors={errors}
                  onChange={(e) => {
                    setValue(`versions.0.templateValues.offerTemplates.${index}.discountValue`, e.target.value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    setIsAmountChanged(true);
                  }}
                  showError={false}
                  selectWidth={50}
                />
          </EnterValueWrapper>
  )
}
const DiscountTypeCondition = ({
  setValue,
  TotalDiscount,
  setAllDiscountValues,
  control,
  register,
  errors,
  totalDiscount,
  disabled,
  setIsTypeChanged,
  setTotalDiscount,
  templateDiscountType,
  shouldDisplayCurrency} : any) => {
  return (
    <LineWrapper gap={8}>
    <StyledLabel>Discount Type</StyledLabel>
    <StyledForSelectbox
      control={control}
      name="versions.0.templateValues.discountType"
      disabled={disabled}
      onChange={() => {
        setIsTypeChanged(true);
        setTotalDiscount('0');
      }}
      validation={{
        required: true,
      }}
      items={
        Object.entries(DiscountTypeRecord()).map(([key, value]) => {
          return {
            id: key,
            name: value,
          };
        }) as any[]
      }
      selectWidth={164}
      defaultValue={DiscountType.MoneyOff}
      initialSelectedItems={templateDiscountType ? [templateDiscountType] : [DiscountType.MoneyOff]}
      version='offer-form'
    />
    {(templateDiscountType === DiscountType.MoneyOff || templateDiscountType === DiscountType.FixedPrice) && (
      <StyledLabel>{templateDiscountType === DiscountType.MoneyOff ? 'Total Discount' : 'Total Cost'}</StyledLabel>
    )}
    {templateDiscountType !== DiscountType.Free && (
     <EnterValueWrapper disabled={disabled} width={147} errors={getIsControlWithError(`total-discount`, errors)}>
     {shouldDisplayCurrency && <Currency />}
        <OfferTemplateTextField
          register={register}
          key={`totalDiscount_${totalDiscount}_${templateDiscountType}`}
          errors={errors}
          name="total-discount"
          disabled={templateDiscountType !== DiscountType.Percent || disabled}
          onChange={(e) => {
            if (templateDiscountType === DiscountType.Percent) {
              setAllDiscountValues(e.target.value);
              setValue(TotalDiscount, e.target.value, {
                shouldValidate: true,
              });
            }
          }}
          validation={{
            min: 0.01,
            max: templateDiscountType === DiscountType.Percent ? 99 : null,
            pattern: /^\d+(?:\.\d{1,2})?$/,
            validate: (v: string) =>
              !Number.isNaN(Number(v)) && templateDiscountType === DiscountType.Percent
                ? Number(v) >= 0.01 && Number(v) < 100
                : Number(v) >= 0.01,
          }}
          value={totalDiscount}
          selectWidth={80}
        />
     </EnterValueWrapper>
    )}
  </LineWrapper>
  )
}

const Row = ({
  getLabel,
  index,
  disabled,
  control,
  templates,
  buySelectboxWidth,
  trigger,
  getValues,
  onProductSelection,
  offerID,
  register,
  templateDiscountType,
  templateDiscountTypeWidth,
  shouldDisplayCurrency,
  discountTypeKey,
  validationMin,
  validationMax,
  remove,
  config,
  validationReturn,
  errors,
  setValue,
  setIsAmountChanged
} : any) => {
  return (
    <><BuyAndSection
      getLabel={getLabel}
      index={index}
      disabled={disabled}
      control={control}
      templates={templates}
      buySelectboxWidth={buySelectboxWidth}
      trigger={trigger}
      getValues={getValues}
      onProductSelection={onProductSelection}
      offerID={offerID}
      register={register} /><LineWrapper gap={8}>
        <StyledLabel>For</StyledLabel>
        <StyledForSelectbox
          key={templateDiscountType}
          disabled
          name="templateDiscountType"
          items={templateDiscountType
            ? ([
              {
                id: templateDiscountType,
                name: DiscountTypeRecord()[templateDiscountType as DiscountType],
              },
            ] as any[])
            : []}
          defaultValue={templateDiscountType}
          initialSelectedItems={templateDiscountType ? [templateDiscountType] : []}
          selectWidth={templateDiscountTypeWidth}
          version='offer-form' />
        {templateDiscountType !== DiscountType.Free &&
          <EnterValueSection
            disabled={disabled}
            shouldDisplayCurrency={shouldDisplayCurrency}
            index={index}
            discountTypeKey={discountTypeKey}
            templateDiscountType={templateDiscountType}
            register={register}
            validationMin={validationMin}
            validationMax={validationMax}
            getValues={getValues}
            config={config}
            validationReturn={validationReturn}
            errors={errors}
            setValue={setValue}
            setIsAmountChanged={setIsAmountChanged} />}
        {index > 1 && !disabled && (
          <ButtonText
            onClick={() => {
              remove(index);
              templates.splice(index, 1);
            } }
          >
            <StyledIcon name="newCircularMinus" width={16} />
          </ButtonText>
        )}
      </LineWrapper></>
  )
}
export const ProductCombo = ({ disabled, onProductSelection, offerID, offerSource, mode }: TemplateProps) => {
  const {
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();
  const { config } = useSelector(marketConfig);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'versions.0.templateValues.offerTemplates',
  });

  const MAX_LENGTH = 4;

  const TotalDiscount = 'total-discount';
  const { discountType: templateDiscountType, shouldDisplayCurrency } = useDiscountType();
  const [templates] = watch(['versions.0.templateValues.offerTemplates']) as any;
  const discountTypeKey = JSON.stringify(templateDiscountType);
  const [discountArray, setDiscountArray] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState('0');
  const [isAmountChanged, setIsAmountChanged] = useState(false);
  const [isTypeChanged, setIsTypeChanged] = useState(false);

  const setAllDiscountValues = (value: string) => {
    templates.forEach((template: any, index: number) => {
      setValue(
        `versions.0.templateValues.offerTemplates.${index}.discountValue`,
        !Number.isNaN(Number(value)) ? Number(value) : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    });
  };

  const setAllTemplateTypes = () => {
    templates.forEach((template: any, index: number) => {
      setValue(`versions.0.templateValues.offerTemplates.${index}.templateType`, '3', {
        shouldValidate: true,
        shouldDirty: true,
      });
    });
  };

  const calculateAndSetTotalDiscount = (offerTemplate: any) => {
    const newTotalDiscount =
      templateDiscountType === DiscountType.MoneyOff || templateDiscountType === DiscountType.FixedPrice
        ? `${discountArray.reduce((a, b) => {
            a = !Number.isNaN(Number.parseFloat(a)) ? Number.parseFloat(a) : 0;
            b = !Number.isNaN(Number.parseFloat(b)) ? Number.parseFloat(b) : 0;
            return +(a + b).toFixed(2);
          }, 0)}`
        : `${offerTemplate[0].discountValue}`;
    setTotalDiscount(newTotalDiscount);
    setValue(TotalDiscount, newTotalDiscount, {
      shouldValidate: offerTemplate.some((template: any) => template.discountValue),
    });
  };

  useEffect(() => {
    if (isTypeChanged) {
      switch (templateDiscountType) {
        case DiscountType.Free:
          setAllDiscountValues('0');
          break;
        case DiscountType.Percent:
        case DiscountType.MoneyOff:
        case DiscountType.FixedPrice:
          setAllDiscountValues(undefined);
          break;
        default:
          setIsTypeChanged(false);
          break;
      }
      setTotalDiscount('0');
      setValue(TotalDiscount, undefined);
      setIsTypeChanged(false);
    }
  }, [isTypeChanged]);

  useEffect(() => {
    if (templates?.length && Number.isFinite(Number(+templates[0].discountValue))) {
      setAllTemplateTypes();
      calculateAndSetTotalDiscount(templates);
    }
  }, [discountArray]);

  useEffect(() => {
    if (templates?.length) {
      setDiscountArray(templates.map((template: any) => +template.discountValue));
      calculateAndSetTotalDiscount(templates);
    }
    if (isAmountChanged) {
      setIsAmountChanged(false);
    }
  }, [isAmountChanged, templates]);

  useEffect(() => {
    const isEditMode = templates?.length;
    if (!isEditMode) {
      append({ buy: '1', buyProducts: undefined, discountValue: undefined });
      append({ buy: '1', buyProducts: undefined, discountValue: undefined });
    } else {
      setDiscountArray(templates.map((template: any) => +template.discountValue));
    }
  }, []);

  const buySelectboxWidth = templateDiscountType === 'fixedPrice' ? 105 : 143.5;
  const freeMin =  DiscountType.Free ? 0 : 0.01;
  const validationMin = templateDiscountType === DiscountType.Percent ? 1 : freeMin;
  const validationMax = templateDiscountType === DiscountType.Percent ? 99 : null;
  const templateDiscountTypeWidth = templateDiscountType === 'fixedPrice' ? 95 : 69.25;

  const getLabel = (index: number) => {
    return index ? 'And' : 'Buy';
  }
  const validationFixedPrice = (v: string) => {
    return templateDiscountType === DiscountType.FixedPrice
    ? Number(v) >= 0.01
    : true;
  }
  const validationReturn = (v: string ) => {
    return !Number.isNaN(Number(v)) && templateDiscountType === DiscountType.Percent
        ? Number(v) >= 1 && Number(v) < 100
        : validationFixedPrice(v)
  }
  return (
    <OfferInfoWrapper disabled={disabled}>
     <DiscountTypeCondition
        setValue={setValue}
        TotalDiscount={TotalDiscount}
        setAllDiscountValues={setAllDiscountValues}
        control={control}
        register={register}
        errors={errors}
        totalDiscount={totalDiscount}
        disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
        setIsTypeChanged={setIsTypeChanged}
        setTotalDiscount={setTotalDiscount}
        templateDiscountType={templateDiscountType}
        shouldDisplayCurrency={shouldDisplayCurrency}
     /> 
      {fields.map((item, index) => (
        <LineWrapperSpaceBetween values={getUniqueProducts(templates[index]?.buyProducts,)}key={`${item.id}_${index}`}>
          <Row
            getLabel={getLabel}
            index={index}
            disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
            control={control}
            templates={templates}
            buySelectboxWidth={buySelectboxWidth}
            trigger={trigger}
            getValues={getValues}
            onProductSelection={onProductSelection}
            offerID={offerID}
            register={register}
            templateDiscountType={templateDiscountType}
            templateDiscountTypeWidth={templateDiscountTypeWidth}
            shouldDisplayCurrency={shouldDisplayCurrency}
            discountTypeKey={discountTypeKey}
            validationMin={validationMin}
            validationMax={validationMax}
            remove={remove}
            config={config}
            validationReturn={validationReturn}
            errors={errors}
            setValue={setValue}
            setIsAmountChanged={setIsAmountChanged}
          />
        </LineWrapperSpaceBetween>
      ))}
    <div>
      {fields.length < MAX_LENGTH && !disabled && (
            <StyledButtonText
              disabled={disabled || (offerSource === OfferSource.DOE && mode === 'edit')}
              onClick={() => {
                append({
                  buy: '1',
                  buyProducts: undefined,
                  discountType: templateDiscountType,
                  discountValue:
                    templateDiscountType === DiscountType.Percent || templateDiscountType === DiscountType.Free
                      ? totalDiscount
                      : undefined,
                });
                if (templateDiscountType === DiscountType.Percent || templateDiscountType === DiscountType.Free) {
                  setAllDiscountValues(totalDiscount);
                }
              }}
            >
              <AddRowWrapper>
                <StyledIcon name="newCircularPlus" width={16} />
                  Add Row
              </AddRowWrapper>
            </StyledButtonText>
          )}
      </div>
    </OfferInfoWrapper>
  );
};
