import React, { useEffect } from 'react';
import { FormMode } from 'utils/types';
import { CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { createCampaignTag } from 'utils/api/campaigns';
import { DistributionType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { capitalize } from 'utils/text';
import { useFormContext, useFormState } from 'react-hook-form';
import { shouldShowFieldInViewMode } from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import VoucherConfigSection from 'pages/campaigns/campaignManagement/components/campaignForm/components/voucherConfigSection/VoucherConfigSection';
import {
  CampaignDescription,
  CampaignName,
  FormColumn,
  FormRow,
  GamingCampaignName,
  GamingFormRow,
  PriorityWrapper,
  SectionContainer,
  StyledCheckbox,
  StyledPriorityCheckbox,
  VoucherDistribution,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/generalDetailsSection/GeneralDetailsSection.style';
import { GeneralDetailsSectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/generalDetailsSection/GeneralDetailsSection.consts';
import { isInArray } from 'utils/array';
import { getIsControlWithError } from 'utils/form';
import { Feature } from 'utils/types/features';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import useFeatureFlag from 'hooks/use-feature-flag';
import { useQuery } from '@apollo/client';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { FetchPolicies, ValidationMessages } from 'utils/types/common';
import dealPriorityConfiguration from 'pages/settings/dealPriority/DealPriorityConfiguration.gqls';
import { components } from 'react-select';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';

const GeneralDetailsSection = ({ mode, isDisabled, offerSource = OfferSource.VCE }: GeneralDetailsSectionProps) => {
  const { register, control, watch, getValues, unregister, trigger, setValue } = useFormContext();
  const message = 'Up to 200 characters';
  const priorityFromDb = getValues('priority');
  const campaignType = getValues('type');
  const { errors } = useFormState();
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Campaign]);
  const { data } = useQuery(dealPriorityConfiguration.queries.getAllDealPriority, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
  });
  const CustomOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{props?.data?.name}</span>
          {props?.data?.isDefault && <span style={{ color: '#aaa' }}>Default</span>}
        </div>
      </components.Option>
    );
  };
  const priorityList = [
    { id: 10000, name: 'Priority 1', value: 10000, isDefault: false },
    { id: 20000, name: 'Priority 2', value: 20000, isDefault: false },
    { id: 30000, name: 'Priority 3', value: 30000, isDefault: false },
    { id: 40000, name: 'Priority 4', value: 40000, isDefault: false },
    { id: 50000, name: 'Priority 5', value: 50000, isDefault: false },
    { id: 60000, name: 'Priority 6', value: 60000, isDefault: false },
  ];

  const isGeneratedNameOn = useFeatureFlag(Feature.GeneratedName);
  const isNedOn = useFeatureFlag(Feature.Ned);
  const isFormEditable = mode === FormMode.Edit;
  const isDoeSource = offerSource === OfferSource.DOE;
  const isDoeEditDisabled = () => isFormEditable && isDoeSource;
  const doeEditDisabled = isDoeEditDisabled();
  const { config } = useSelector(marketConfig);
  const { enableDigitalDownload } = config;
  const [isLocalCampaign, selectedTags, type, isGeneratedName, title, description, isPriority, isTopPriority] = watch([
    'isLocalCampaign',
    'tags',
    'type',
    'isGeneratedName',
    'title',
    'description',
    'isPriority',
    'isTopPriority',
    'schedule.campaignStart',
  ]);
  let initalPriority: any = [];
  const dealPriorityArray = data?.getDealPriority || [];
  if (data && data.getDealPriority) {
    initalPriority = dealPriorityArray
      ?.slice()
      .sort((a: { priority: number }, b: { priority: number }) => a?.priority - b?.priority)
      .map((item: any) => ({
        ...item,
        name: CampaignTypeLabel[item?.campaignType as CampaignType],
      }));
  }

  let arrayForAutoGenerateNames;
  const shouldFetchAutoGenerateNames = mode === FormMode.New;
  if (shouldFetchAutoGenerateNames) {
    const { data } = useQuery(campaignsGqls.queries.getCampaignTypesForName, {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
    });

    if (data) {
      arrayForAutoGenerateNames = data;
    }
  }


  let campaignTypes: any[] = Object.values(CampaignType).map((cType) => {
    return {
      id: cType,
      name: CampaignTypeLabel[cType],
    };
  });

  let filteredCampaignTypes = [...campaignTypes]; 
  const filters = [
    { condition: !isNedOn, filter: (campaignType: { id: CampaignType; }) => campaignType.id !== CampaignType.Ned },
    { condition: !enableDigitalDownload, filter: (campaignType: { id: CampaignType; }) => campaignType.id !== CampaignType.LoyaltyExternalVoucher },
    { condition: isLocalCampaign, filter: (campaignType: { id: CampaignType; }) => [CampaignType.LocationBased, CampaignType.Segmented].includes(campaignType.id) }
  ];

  filters.forEach(({ condition, filter }) => {
    if (condition) {
      filteredCampaignTypes = filteredCampaignTypes.filter(filter);
    }
  });

  campaignTypes = filteredCampaignTypes; 

  const onTagCreation = async (tagName: string) => {
    try {
      const tag = await createCampaignTag(tagName);
      return tag?.data?.createTag;
    } catch (e) {
      showToast(
        MessageType.Error,
        `Failed to add tag${e.message.includes('duplication item')
          ? ` - ${tagName} already exists in another entity. Please update tag entity or contact Admin`
          : ''
        }
        ${e.message.includes('char limit') ? ` - Exceeds 50 Character limit` : ''}`,
      );
      return null;
    }
  };

  useEffect(() => {
    if (mode === FormMode.New && isGeneratedNameOn) {
      if (isInArray([CampaignType.LocationBased, CampaignType.Segmented], type)) {
        register('isGeneratedName', { value: false, shouldUnregister: true });
      } else {
        unregister('isGeneratedName');
      }
    }
    if (type === null) {
      setValue('isPriority', '');
      setValue('isTopPriority', false);
    }
  }, [type]);

  useEffect(() => {
    const setPriorityValues = (priority: any, topPriority: boolean) => {
      setValue('isPriority', priority);
      setValue('isTopPriority', topPriority);
    };
    const handleNoPriorityValues = () => {
      if (priorityFromDb === 0) {
        setPriorityValues(null, true);
      } else if (priorityFromDb) {
        setPriorityValues(priorityFromDb, false);
      } else {
        if (campaignType !== CampaignType.Loyalty) {
          const priorityValue = initalPriority?.find(
            (campaign: any) => campaign?.campaignType === campaignType,
          )?.priority;
          if (priorityValue) {
            const priorityId = priorityList?.find((priority: any) => priority?.value === priorityValue)?.id;
            setValue('isPriority', priorityId);
            setValue('isTopPriority', false);
          }
        }
      }
    };

    if (
      (mode === FormMode.View || mode === FormMode.Edit || mode === FormMode.Duplicate) &&
      isTopPriority === undefined &&
      isPriority === undefined
    ) {
      handleNoPriorityValues();
    }
  }, [data]);

  useEffect(() => {
    if (isGeneratedName) {
      setValue('title', '');
      unregister('title');
    } else if (isGeneratedName === false) {
      register('title', {
        required: ValidationMessages.RequiredField,
        maxLength: { value: 200, message: message },
        shouldUnregister: true,
      });
      trigger('title');
    }
  }, [isGeneratedName]);

  useEffect(() => {
    if (
      (mode === FormMode.New || mode === FormMode.Edit || mode === FormMode.Duplicate) &&
      type !== CampaignType.Loyalty
    ) {
      const selectedItemId = getValues('isPriority');
      const priorityValue = initalPriority?.find((campaign: any) => campaign?.campaignType === type)?.priority;
      const priorityId = priorityList?.find((priority) => priority?.value === priorityValue)?.id;
      if (selectedItemId !== priorityId) {
        priorityList?.forEach((item) => {
          if (item.id === priorityId) {
            item.isDefault = true;
          }
        });
      }
    }
  });

  useEffect(() => {
    loadTags();
  }, []);
  useEffect(() => { }, [title, description]);
  useEffect(() => {
    trigger('schedule.campaignStart');
  }, [title]);

  const handlePriorityChange = (selectedType: any) => {
    if (mode === FormMode.New || mode === FormMode.Edit || mode === FormMode.Duplicate) {
      if (selectedType.id === CampaignType.Loyalty) {
        setValue('isPriority', null);
        setValue('isTopPriority', false);
      } else {
        const priorityValue = initalPriority?.find(
          (campaign: any) => campaign?.campaignType === selectedType.id,
        )?.priority;
        if (priorityValue) {
          const priorityId = priorityList?.find((priority) => priority?.value === priorityValue)?.id;
          setValue('isPriority', priorityId);
          setValue('isTopPriority', false);
        }
      }
    }
  };

  function handleTopPriorityChange() {
    if ((mode === FormMode.Edit || mode === FormMode.Duplicate) && isTopPriority && !isPriority) {
      const priorityValue = initalPriority?.find((campaign: any) => campaign?.campaignType === campaignType)?.priority;
      if (priorityValue) {
        const priorityId = priorityList?.find((priority) => priority?.value === priorityValue)?.id;
        setValue('isPriority', priorityId);
      }
    }
    setValue('isTopPriority', !isTopPriority);

  }
  return (
    <SectionContainer>
      <FormRow type={type}>
        <NewSelectbox
          key={JSON.stringify(campaignTypes)}
          control={control}
          errors={errors}
          name="type"
          label="Campaign Type"
          placeholder="Select"
          disabled={isDisabled || getValues('offerVersion')?.templateValues?.templateType === '6' || doeEditDisabled}
          validation={{
            required: ValidationMessages.RequiredField,
            validate: {
              loyalty: (value: any) => {
                if (value === CampaignType.Loyalty) {
                  const offerVersion = getValues('offerVersion');
                  if (
                    offerVersion?.translations[0]?.title.length > 100 ||
                    offerVersion?.translations[0]?.subtitle.length > 100
                  ) {
                    return 'Up to 100 characters';
                  }
                }
              },
            },
          }}
          onChange={(selectedType) => handlePriorityChange(selectedType)}
          items={campaignTypes as any[]}
          labelIsHorizontal={type === CampaignType.Voucher ? false : true}
          selectWidth={type === CampaignType.Voucher ? 158 : 257}
          version="campaign-form"
          containerGap={8}
        />
        {type !== CampaignType.Voucher && (
          
        <FormColumn>
          <CampaignName
            register={register}
            errors={errors}
            name="title"
            label="Campaign Name"
            value={getValues('title')}
            placeholder={isGeneratedName ? '' : 'Enter'}
            disabled={isDisabled || isGeneratedName || doeEditDisabled}
            validation={
              !isGeneratedName
                ? {
                  required: ValidationMessages.RequiredField,
                  maxLength: { value: 200, message: message },
                }
                : {}
            }
            labelIsHorizontal
            isGeneratedName={isGeneratedName}
            version={'campaign-form'}
          />
          <FeatureGuard features={[{ feature: Feature.GeneratedName }]}>
            {mode === FormMode.New && isInArray(arrayForAutoGenerateNames?.getTypesFromConfig || [], type) && (
              <StyledCheckbox
                control={control}
                name="isGeneratedName"
                nameHasError={getIsControlWithError('title', errors)}
                label="VCE generated name"
                defaultValue={isGeneratedName}
              />
            )}
          </FeatureGuard>
        </FormColumn>
        )}
        {type === CampaignType.Voucher && (
          <VoucherDistribution
            control={control}
            name="voucherConfig.distribution"
            radioList={Object.values(DistributionType).map((distributionType) => ({
              value: distributionType,
              label: capitalize(distributionType),
            }))}
            defaultValue={DistributionType.Digital}
            disabled={isDisabled || doeEditDisabled}
          />
        )}
      </FormRow>
      <VoucherConfigSection isDisabled={isDisabled || doeEditDisabled} mode={mode} />
      <GamingFormRow type={type} showTags={shouldShowFieldInViewMode(mode, selectedTags)}>
        {type === CampaignType.Voucher && (
          <GamingCampaignName
            register={register}
            errors={errors}
            name="title"
            label="Campaign Name"
            value={getValues('title')}
            placeholder={isGeneratedName ? '' : 'Enter'}
            disabled={isDisabled || isGeneratedName}
            validation={
              !isGeneratedName
                ? {
                  required: ValidationMessages.RequiredField,
                  maxLength: { value: 200, message: message },
                }
                : {}
            }
            labelIsHorizontal
            isGeneratedName={isGeneratedName}
            version='campaign-form'
          />)}
        {shouldShowFieldInViewMode(mode, selectedTags) && (
          <FormRow type={type}>
            <NewSelectbox
              key={`${Boolean(tags.length)}`}
              control={control}
              errors={errors}
              name="tags"
              label="Campaign Tags"
              placeholder="Select"
              disabled={mode === FormMode.View}
              multiple
              items={tags}
              maxItems={1}
              withSearch
              withAmount
              onCreateOption={(tagName: string) => onTagCreation(tagName)}
              labelIsHorizontal
              reset
              selectWidth={type === CampaignType.Voucher ? 257 : 530}
              limit={10}
              version="campaign-form"
              containerGap={8}
            />
          </FormRow>
        )}
      </GamingFormRow>
      {shouldShowFieldInViewMode(mode, getValues('description')) && (
        <CampaignDescription
          register={register}
          errors={errors}
          name="description"
          label="Description"
          placeholder="Enter Description"
          disabled={mode === FormMode.View}
          validation={{
            maxLength: { value: 4000, message: 'Up to 4000 characters' },
          }}
          rows={1}
          labelIsHorizontal
        />
      )}
      {type !== CampaignType.Loyalty && type !== CampaignType.LoyaltyExternalVoucher && (
        <>
          <PriorityWrapper>
            <NewSelectbox
              key={`${JSON.stringify(priorityList)}`}
              errors={errors}
              name="isPriority"
              label="Priority"
              placeholder="Select"
              disabled={mode === FormMode.View || isTopPriority || offerSource === OfferSource.DOE}
              onChange={(selectedItem: any) => {
                setValue('isPriority', selectedItem.id);
              }}
              items={priorityList as any}
              initialSelectedItems={[getValues('isPriority') || '']}
              labelIsHorizontal
              selectWidth={257}
              customOption={{ Option: CustomOption, IndicatorSeparator: (): any => null }}
              version="campaign-form"
              containerGap={8}
            />
            <StyledPriorityCheckbox
              label="Top Priority"
              checked={isTopPriority}
              onClick={handleTopPriorityChange}
              disabled={mode === FormMode.View || offerSource === OfferSource.DOE}
            />
          </PriorityWrapper>
        </>
      )}
    </SectionContainer>
  );
};

export default GeneralDetailsSection;
