import React, { useEffect, useState } from 'react';
import Tooltip from 'components/shared/tooltip/Tooltip';
import {
  Approve,
  ButtonContained,
  ButtonContainedReject,
  ButtonContainedSave,
  ButtonContainedSaveAs,
  ButtonOutlined,
  Save,
} from 'components/shared/button';
import ReactTooltip from 'react-tooltip';
import { store } from 'app/store';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { ApprovalStatus, FormMode } from 'utils/types';
import { CampaignType, VoucherGroup } from 'utils/types/campaigns';
import { isInArray } from 'utils/array';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import {
  archiveCampaign,
  getVoucherDetails,
  handleDeployOrApproveActionClick,
  rejectCampaign,
  revokeCampaign,
  stopCampaignAssociation,
  submitCampaignForApproval,
} from 'pages/campaigns/campaignManagement/components/campaignForm/utils/CampaignActions';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { hideTooltip } from 'utils/tooltip';
import { EntityApproveButton } from 'pages/shared/entityApproveButton/EntityApproveButton';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { EntityType } from 'pages/shared/entityApproveButton/EntityApproveButton.consts';
import { offersSelection } from 'app/genericSlices/offers';
import {
  FormFooter,
  FormRow,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter.style';
import {
  CampaignActions,
  CampaignExtendedProps,
  CampaignFormFooterProps,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/campaignFormFooter/CampaignFormFooter.consts';
import { useHistory } from 'react-router-dom';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { getMarketDatetime } from 'utils/date';
import {
  shouldReDeploy,
  validateCampaignVoucher,
} from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';
import OfferToolTip from 'components/shared/tooltip/OfferToolTip';
import { OfferFilters, OfferSource } from 'pages/offers/offerManagement/Offers.const';
import {
  getHasRevokeButton,
  getHasStopAssociation,
  getIsApproveDisabled,
  getStoppedAssociationVoucher,
  getSubmitForApprovalButtonDisabled,
} from './CampaignFormFooter.util';
import { CancelButton } from 'pages/offers/offerManagement/components/offerForm/OfferForm.style';

const CampaignFormFooter = ({
  mode,
  campaignStatus,
  alert,
  isSubmitting,
  setIsSubmitting,
  isLocked,
  onSubmit,
  className,
  fromCalendar = false,
  dateTimeConfig,
  inProgress = false,
  offerSource = OfferSource.VCE,
  voucherState,
}: CampaignFormFooterProps) => {
  const { control, getValues, reset, watch } = useFormContext();
  const { endTimezone, startTimezone } = dateTimeConfig;
  const { isDirty, isValid } = useFormState();
  const [isFormValid, setIsFormValid] = useState(isValid && !isSubmitting);
  const [campaignType, campaignVoucherDistribution, offerVersion] = useWatch({
    control,
    name: ['type', 'voucherConfig.distribution', 'offerVersion'],
  });
  const history = useHistory();
  const isLocalCampaign = watch('isLocalCampaign');
  const offerStatusDisabled = offerVersion && offerVersion.status !== ApprovalStatus.Approved;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isDirty, mode]);

  useEffect(() => {
    setIsFormValid(isValid && !isSubmitting);
  }, [isValid, isSubmitting]);

  const [isRejectButtonClicked, setIsRejectButtonClicked] = useState(false);

  const handleRejectClick = (rejectionComment: any) => {
    if (!isRejectButtonClicked) {
      setIsRejectButtonClicked(true);
      onActionClicked(CampaignActions.Reject, rejectionComment);
      hideTooltip('#reject-tooltip');
      ReactTooltip.hide();
    }
  };

  const [isRevokeButtonClicked, setIsRevokeButtonClicked] = useState(false);

  const handleRevokeClick = () => {
    if (!isRevokeButtonClicked) {
      setIsRevokeButtonClicked(true);
      onActionClicked(CampaignActions.Revoke);
      hideTooltip('#revoke-tooltip');
    }
  };

  const onActionClicked = async (actionName: CampaignActions, extraData?: any) => {
    const campaign = getValues() as CampaignExtendedProps;
    store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
    switch (actionName) {
      case CampaignActions.Edit:
        store.dispatch(
          openModal({
            modal: Modals.CampaignModal,
            props: { mode: FormMode.Edit, campaign: cloneDeep(campaign) },
          }),
        );
        reset(campaign);
        break;
      case CampaignActions.SubmitForApproval: {
        if (campaignType === CampaignType.LoyaltyExternalVoucher) {
          const voucherData: VoucherGroup = await getVoucherDetails(
            mode,
            +offerVersion?.templateValues?.nonFoodDiscountRewards,
            voucherState,
          );
          if (validateCampaignVoucher(voucherData, campaign.schedule, dateTimeConfig)) {
            await submitCampaignForApproval(campaign);
          }
        } else {
          await submitCampaignForApproval(campaign);
        }
        break;
      }
      case CampaignActions.Archive: {
        await archiveCampaign(campaign, true);
        break;
      }
      case CampaignActions.Duplicate: {
        const dupCampaign = {
          ...pick(campaign, [
            'id',
            'type',
            'title',
            'description',
            'schedule',
            'locations',
            'voucherConfig',
            'restaurantEligibility',
            'isNational',
            'localSchedule',
            'isPriority',
            'isTopPriority',
          ]),
          isLocalCampaign: campaign.status === ApprovalStatus.Draft ? campaign.isLocalCampaign : false,
          offerVersion,
          tags: campaign.tags.map((t) => t.id),
          ...(campaign.isLocalCampaign
            ? {
                localSchedule: {
                  zone:
                    campaign.status === ApprovalStatus.Draft && campaign.localSchedule.zone
                      ? campaign.localSchedule.zone?.id
                      : null,
                  period: campaign.status === ApprovalStatus.Draft ? campaign.localSchedule.period : null,
                },
              }
            : {}),
        };
        store.dispatch(
          openModal({
            modal: Modals.CampaignModal,
            props: { mode: FormMode.Duplicate, campaign: cloneDeep(dupCampaign) },
          }),
        );
        reset(dupCampaign);
        break;
      }
      case CampaignActions.ReDeploy:
      case CampaignActions.Deploy:
      case CampaignActions.Approve: {
        if (campaign.schedule?.campaignEnd <= getMarketDatetime(endTimezone)) {
          showToast(MessageType.Error, `Campaign End time is in the past`);
          break;
        }
        setIsSubmitting(true);
        if (campaignType === CampaignType.LoyaltyExternalVoucher) {
          const voucherData: VoucherGroup = await getVoucherDetails(
            mode,
            +offerVersion?.templateValues?.nonFoodDiscountRewards,
            voucherState,
          );
          if (validateCampaignVoucher(voucherData, campaign.schedule, dateTimeConfig)) {
            await handleDeployOrApproveActionClick(campaign, actionName);
          }
        } else {
          await handleDeployOrApproveActionClick(campaign, actionName);
        }
        setIsSubmitting(false);
        break;
      }
      case CampaignActions.Reject: {
        await rejectCampaign(campaign, extraData);
        break;
      }
      case CampaignActions.Revoke: {
        await revokeCampaign(campaign);
        break;
      }
      case CampaignActions.StopAssociation: {
        await stopCampaignAssociation(campaign);
        break;
      }
      case CampaignActions.Unarchive: {
        await archiveCampaign(campaign, false);
        break;
      }
      default:
    }
  };

  const isApproveDisabled = getIsApproveDisabled(isFormValid, campaignStatus, alert, voucherState?.voucherError);

  const hasStopAssociation = getHasStopAssociation(campaignType, campaignVoucherDistribution);

  const stoppedAssociationVoucher = getStoppedAssociationVoucher(
    campaignType,
    campaignVoucherDistribution,
    campaignStatus,
  );

  const shouldReDeployCampaign = shouldReDeploy(getValues() as CampaignExtendedProps, startTimezone);

  const isExternalVoucherCampaign = campaignType === CampaignType.LoyaltyExternalVoucher;

  const isVoucherValid = isFormValid && !voucherState.voucherError;

  const hasReDeploy = shouldReDeployCampaign && (!isExternalVoucherCampaign || isVoucherValid);


  const hasRevoke = getHasRevokeButton(campaignType, campaignVoucherDistribution, campaignStatus);

  const hasDeploy = campaignStatus === ApprovalStatus.Approved && getValues().isTriggerEvent;
  const isSubmitForApprovalDisabled = getSubmitForApprovalButtonDisabled(
    isFormValid,
    offerVersion,
    voucherState?.voucherError,
  );
  const ReDeployButton = (
    <ButtonOutlined onClick={() => onActionClicked(CampaignActions.ReDeploy)}>Re-Deploy</ButtonOutlined>
  );
  const StopAssociationButton = (
    <ButtonContainedSave onClick={() => null} data-tip data-for="stop-association-tooltip">
      Stop Association
    </ButtonContainedSave>
  );
  const UnarchiveButton = (
    <ButtonContainedSave onClick={() => onActionClicked(CampaignActions.Unarchive)}>Unarchive</ButtonContainedSave>
  );
  const SubmitForApprovalButton = (
    <ButtonContainedSaveAs
      onClick={() => onActionClicked(CampaignActions.SubmitForApproval)}
      disabled={!isFormValid || offerStatusDisabled}
    >
      Submit for Approval
    </ButtonContainedSaveAs>
  );
  const ApproveButton = (
    <EntityApproveButton entity={getValues()} entityType={EntityType.Campaign}>
      <Approve disabled={isApproveDisabled} onClick={() => onActionClicked(CampaignActions.Approve)}>
        Approve
      </Approve>
    </EntityApproveButton>
  );
  const DeployButton = (
    <EntityApproveButton entity={getValues()} entityType={EntityType.Campaign}>
      <ButtonContainedSave disabled={!isFormValid} onClick={() => onActionClicked(CampaignActions.Deploy)}>
        Deploy
      </ButtonContainedSave>
    </EntityApproveButton>
  );
  const RejectButton = (
    <ButtonContainedReject onClick={() => null} data-tip data-for="reject-tooltip">
      Reject
    </ButtonContainedReject>
  );
  const RevokeButton = (
    <ButtonContainedSave
      data-for={stoppedAssociationVoucher ? 'show-stop-association-msg' : 'revoke-tooltip'}
      data-tip
      onClick={() => null}
    >
      Revoke
    </ButtonContainedSave>
  );

  const DeployedButtons = (
    <>
      {offerSource === OfferSource.VCE ? (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {hasRevoke && RevokeButton}
        </RoleGuard>
      ) : (
        <RoleGuard roles={[UserRole.Admin, UserRole.SysAdmin]}>{hasRevoke && RevokeButton}</RoleGuard>
      )}
    </>
  );

  const ActiveButtons = (
    <>
      {offerSource === OfferSource.VCE ? (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {hasStopAssociation && StopAssociationButton}
          {hasRevoke && RevokeButton}
        </RoleGuard>
      ) : (
        <RoleGuard roles={[UserRole.Admin, UserRole.SysAdmin]}>{hasRevoke && RevokeButton}</RoleGuard>
      )}
    </>
  );

  const DraftOrRejectedButtons = (
    <>
      {offerSource === OfferSource.VCE && (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {!isLocalCampaign && SubmitForApprovalButton}
        </RoleGuard>
      )}
    </>
  );

  const ApprovedButtons = (
    <>
      {offerSource === OfferSource.VCE ? (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {!isLocalCampaign && RejectButton}
          {hasDeploy && DeployButton}
        </RoleGuard>
      ) : (
        <RoleGuard roles={[UserRole.Admin, UserRole.SysAdmin]}>{!isLocalCampaign && RejectButton}</RoleGuard>
      )}
    </>
  );

  const StopAssociationButtons = (
    <>
      {offerSource === OfferSource.VCE && (
        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
          {RevokeButton}
        </RoleGuard>
      )}
    </>
  );

  const viewModeButtonsByStatus: Partial<Record<ApprovalStatus, JSX.Element>> = {
    [ApprovalStatus.Draft]: DraftOrRejectedButtons,
    [ApprovalStatus.Rejected]: DraftOrRejectedButtons,
    [ApprovalStatus.Deployed]: DeployedButtons,
    [ApprovalStatus.DeploymentPending]: DeployedButtons,
    [ApprovalStatus.Active]: ActiveButtons,
    [ApprovalStatus.AssociationStopped]: StopAssociationButtons,
    [ApprovalStatus.PendingApproval]: (
      <FormRow>
        {ApprovedButtons}
        {!isLocalCampaign && ApproveButton}
      </FormRow>
    ),
    [ApprovalStatus.Approved]: ApprovedButtons,
    [ApprovalStatus.Archived]: UnarchiveButton,
  };

  const renderViewModeButtons = (): JSX.Element => {
    if (fromCalendar) {
      return (
        <ButtonContained
          onClick={() => {
            history.push(`/campaigns?id=${getValues().externalId}`);
            store.dispatch(closeModal());
          }}
        >
          Go to Campaign Management
        </ButtonContained>
      );
    }
    return viewModeButtonsByStatus[campaignStatus];
  };
  const getButtonsByModeAndStatus = (): JSX.Element => {
    switch (mode) {
      case FormMode.New:
      case FormMode.Duplicate:
        return (
          <>
            <ButtonContainedSave onClick={onSubmit(true)} disabled={!isFormValid}>
              Save as Draft
            </ButtonContainedSave>
            {!isLocalCampaign && (
              <ButtonContainedSaveAs onClick={onSubmit(false)} disabled={isSubmitForApprovalDisabled}>
                Save & Submit for Approval
              </ButtonContainedSaveAs>
            )}
          </>
        );
      case FormMode.Edit:
        if (offerSource === OfferSource.VCE) {
          if (
            isInArray(
              [ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped],
              campaignStatus,
            )
          ) {
            return (
              <ButtonContained
                onClick={() => null}
                disabled={!isFormValid || offerStatusDisabled}
                data-tip
                data-for="deployed-active-tooltip"
              >
                Save
              </ButtonContained>
            );
          }
          if (isLocalCampaign) {
            return (
              <Save onClick={onSubmit(campaignStatus === ApprovalStatus.Draft)} disabled={!isFormValid}>
                Save
              </Save>
            );
          }
          return (
            <>
              <ButtonContainedSave onClick={onSubmit(true)} disabled={!isFormValid}>
                Save as Draft
              </ButtonContainedSave>
              <ButtonContainedSave onClick={onSubmit(false)} disabled={isSubmitForApprovalDisabled}>
                Save & Submit for Approval
              </ButtonContainedSave>
            </>
          );
        } else {
          return (
            <ButtonContained
              onClick={() => null}
              disabled={isSubmitForApprovalDisabled}
              data-tip
              data-for="deployed-active-tooltip"
            >
              Save
            </ButtonContained>
          );
        }
      case FormMode.View:
        return renderViewModeButtons();
      default:
        return null;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement | HTMLDivElement>) => {
    if (event.key === 'Enter') {
      store.dispatch(closeModal());
    }
  };

  return (
    <FormFooter className={className}>
      <CancelButton
        data-for="cancel-tooltip"
        {...(isDirty || mode === FormMode.Duplicate
          ? { 'data-tip': true, onClick: () => null }
          : { onClick: () => store.dispatch(closeModal()) })}
        onKeyDown={handleKeyDown}
      >
        Cancel
      </CancelButton>
      {!isLocked && getButtonsByModeAndStatus()}
      <OfferToolTip
        id="cancel-tooltip"
        content="Are you sure you want to cancel?"
        onDisapproveClick={() => {
          hideTooltip('#cancel-tooltip');
        }}
        onApproveClick={() => {
          store.dispatch(offersSelection.actions.setFilter({ filter: [OfferFilters.Zone], value: null }));
          store.dispatch(closeModal());
        }}
      />
      <Tooltip
        id="revoke-tooltip"
        content={
          campaignType === CampaignType.Voucher
            ? 'Please consult with legal prior to revoking voucher campaign'
            : 'Are you sure you want to revoke this campaign?'
        }
        approveMsg="Yes, Revoke"
        onDisapproveClick={() => {
          hideTooltip('#revoke-tooltip');
          setIsRevokeButtonClicked(false);
        }}
        onApproveClick={handleRevokeClick}
      />
      <Tooltip
        id="show-stop-association-msg"
        content={'Unable to revoke the digital voucher as the association has already been stopped.'}
        approveMsg="Close"
        onApproveClick={() => {
          hideTooltip('#show-stop-association-msg');
        }}
      />
      <Tooltip
        id="stop-association-tooltip"
        content="Are you sure you want to stop association of this campaign?"
        onDisapproveClick={() => {
          hideTooltip('#stop-association-tooltip');
        }}
        approveMsg="Yes, Stop Association"
        onApproveClick={() => onActionClicked(CampaignActions.StopAssociation)}
      />
      <Tooltip
        id="reject-tooltip"
        content="Are you sure you want to reject?"
        onDisapproveClick={() => {
          hideTooltip('#reject-tooltip');
          ReactTooltip.hide();
          setIsRejectButtonClicked(false);
        }}
        eventOff={null}
        approveMsg="Yes, Reject"
        isWithResponse
        responsePlaceholder="Enter reject reason"
        onApproveWithResponseClick={(rejectionComment) => handleRejectClick(rejectionComment)}
      />
      <Tooltip
        id="deployed-active-tooltip"
        content={
          getValues('type') === CampaignType.Voucher
            ? `You are about to update ${
                campaignStatus === ApprovalStatus.Deployed ? 'a deployed' : 'an active'
              } voucher campaign. Please consult the legal department before proceeding.`
            : `Are you sure you want to update ${
                campaignStatus === ApprovalStatus.Active ? 'an active' : 'a deployed'
              } campaign?`
        }
        onDisapproveClick={() => {
          hideTooltip('#deployed-active-tooltip');
        }}
        approveMsg="OK, Save"
        onApproveClick={onSubmit(false)}
      />
    </FormFooter>
  );
};

export default CampaignFormFooter;
