import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HelpMenu from 'components/header/components/helpMenu/HelpMenu';
import BrandLogo from '../shared/brandLogo/BrandLogo';
import { Container, Line, StyledEnvironmentLabel, StyledLink, StyledTitle } from './Header.style';
import { Environments, EnvironmentType, getTabByPath, HeaderProps, mainTabs } from './Header.consts';
import TabStrip from '../shared/tabStrip/NewTabStrip';
import UserMenu from './components/userMenu/UserMenu';
import NotificationsMenu from './components/notificationsMenu/NotificationsMenu';
import sseService from 'utils/sseService';
import {MarketDropdownMenu} from './components/marketDropdownMenu/MarketDropdownMenu';

const Header = ({ user }: HeaderProps) => {
  const history = useHistory();
  const [selectedTabId, setSelectedTabId] = useState<string>(`tab-${getTabByPath(history.location.pathname)}`);
  const [environment, setEnvironment] = useState<Environments>(EnvironmentType.Prod);

  const startSSEConnection = () => {
    const isHttps = () => window.location.protocol.includes('s');
    sseService.openConnection(`${
      isHttps()
        ? `https://${window.location.host.split(':')[0]}/gateway/sse`
        : `http://${window.location.host.split(':')[0]}:5780/gateway/sse`
    }`);
    return () => {
      sseService.closeConnection();
    };
  };
  
  useEffect(() => {
    const cleanup = startSSEConnection();
    return cleanup;
  }, []);

  useEffect(() => {
    setSelectedTabId(`tab-${getTabByPath(history.location.pathname)}`);
  }, [history.location]);

  useEffect(() => {
    const { host } = window.location;
    switch (true) {
      case host.includes('stg.euc1.dy'):
        setEnvironment(EnvironmentType.Stage);
        break;
      case host.includes('mte.euc1.dy'):
        setEnvironment(EnvironmentType.Mte);
        break;
      case host.includes('.use1.dy'):
        setEnvironment(EnvironmentType.Int);
        break;
      case host.includes('.staging-use1.dy'):
        setEnvironment(EnvironmentType.Test);
        break;
      case host.includes('.dev-use1.dy'):
        setEnvironment(EnvironmentType.Dev);
        break;
      case host.includes('localhost'):
        setEnvironment(EnvironmentType.Local);
        break;
      default:
        setEnvironment(EnvironmentType.Prod);
    }
  }, []);

  return (
    <Container environment={environment}>
      <StyledLink to="/">
        <BrandLogo width={48} />
        <StyledTitle>
          <Line>Value</Line>
          <Line>Creation</Line>
          <Line>Engine</Line>
        </StyledTitle>
      </StyledLink>
      <TabStrip tabs={mainTabs} selectedTabId={selectedTabId} />
      {/* TODO: considering changing it to "Mega Admin" role and not by email */}
      {user.email.includes('@dynamicyield.com') && (
        <StyledEnvironmentLabel title={`'${environment}' environment, this label won't appear on prod`}>
          {environment}
        </StyledEnvironmentLabel>
      )}
      <MarketDropdownMenu/>
      <NotificationsMenu />
      <HelpMenu />
      <UserMenu user={user} />
    </Container>
  );
};

export default Header;
