import React, { useEffect, useState } from 'react';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName, tabToPathMap } from 'components/header/Header.consts';
import { NewStyledHeader, NewStyledTotalAmountWithTable, NewTableContainer } from 'pages/shared/shared.style';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { useSelector } from 'react-redux';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { hideActionsCell, showActionsCell } from 'components/shared/table/NewTable.util';
import { ApprovalStatus, DateTimeConfig, FormMode, OrderDirection, SSE } from 'utils/types';
import { isInArray } from 'utils/array';
import { useUrlFilters } from 'hooks/use-url-filters';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { marketConfig } from 'app/slices/config';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { getCampaignById, getCampaignsForReport } from 'utils/api/campaigns';
import isEqual from 'lodash/isEqual';
import { GridSortModel } from '@mui/x-data-grid-pro';
import NewAddNewButton from 'pages/shared/addNewButton/NewAddNewButton';
import { vceCampaignsPage, CampaignsUpdates } from 'app/genericSlices/campaigns';
import { useHistory } from 'react-router-dom';
import { getLastWeek } from 'utils/date';
import { AggregationType, Interval, RedemptionFilterType } from 'pages/reports/redemptions/Redemptions.consts';
import { useToastError } from 'hooks/use-toast-error';
import { mapOrder } from 'utils/mapping';
import CampaignsFilterBar from 'pages/campaigns/campaignManagement/components/campaignsFilterBar/CampaignsFilterBar';
import {
  CampaignFilterType,
  CampaignProps,
  CampaignReportProps,
  CampaignsSelection,
} from 'pages/campaigns/campaignManagement/Campaigns.consts';
import {
  ActionButtonsContainer,
  NewStyledTable,
  StyledTotalAmountContainer,
  NewTableHeader,
  TitleActionButtonsContainer,
  NewTableBorder,
  NewTableSubHeader,
} from 'pages/campaigns/campaignManagement/Campaigns.style';
import QuickFilters from 'pages/campaigns/campaignManagement/components/quickFilters/QuickFilters';
import { getCampaignsTableProps } from 'pages/campaigns/campaignManagement/CampaignTableProps';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { resetRedemptions, setFilter, setIsInitialEntrance } from 'app/slices/redemptions';
import CampaignBulkActions from 'pages/campaigns/campaignManagement/components/campaignBulkActions/CampaignBulkActions';
import {
  BulkCampaignMutationResult,
  CampaignBulkAction,
} from 'pages/campaigns/campaignManagement/components/campaignBulkActions/CampaignBulkActions.consts';
import { capitalize } from 'utils/text';
import { CampaignAlert } from 'utils/types/campaigns';
import { users } from 'app/slices/users';
import useQueryInterval from 'hooks/use-query-polling';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { NewStyledButton, NewStyledDownload } from 'pages/shared/downloadCsvButton/DownloadCsvButton.style';
import { downloadCSV } from 'utils/download';
import { generateCampaignRow, generateColumnNames } from 'utils/campaign';
import { Loader, StyledLoader, StyledLoaderContainerCampaign } from 'components/shared/loader';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { FetchPolicies } from 'utils/types/common';
import sseService from 'utils/sseService';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import NewPageContainer from 'pages/shared/pageContainer/NewPageContainer';
import { CampaignLoaderWrapper } from '../calendar/components/calendarView/CalendarView.style';

const Campaigns = () => {
  const { order, filters } = useSelector(vceCampaignsPage.campaignsState);
  const [bulkAction, setBulkAction] = useState<CampaignBulkAction>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignsUpdates, setCampaignsUpdates] = useState({
    type: '',
    record: '',
  });

  const forceStatusFilter = bulkAction
    ? (bulkAction === CampaignBulkAction.BulkSubmitForApproval
      ? [ApprovalStatus.Draft]
      : [ApprovalStatus.PendingApproval])
    : null;

  const getFiltersFromFiltersBar = (filterValues: any) => {
    const priorityToggle = filterValues[CampaignFilterType.Priority] || 'false';
    const statusFilters = forceStatusFilter ? forceStatusFilter : filterValues[CampaignFilterType.Status] || [];
    const typeFilters = filterValues[CampaignFilterType.Type] || [];
    const tagFilters = filterValues[CampaignFilterType.Tags] || [];
    const alertFilters = filterValues[CampaignFilterType.Alert] || [];
    const periodFilters = filterValues[CampaignFilterType.Period] || [];
    const zoneFilters = filterValues[CampaignFilterType.Zone] || [];
    const onlyLocal = filterValues[CampaignFilterType.OnlyLocal] || 'false';
    const isNational = filterValues[CampaignFilterType.National] || 'false';
    const isSearch = filterValues[CampaignFilterType.SearchQuery] ? 'true' : 'false';
    return {
      priorityToggle,
      statusFilters,
      typeFilters,
      tagFilters,
      alertFilters,
      periodFilters,
      zoneFilters,
      onlyLocal,
      isNational,
      isSearch,
    };
  };

  const priorityFilters = getFiltersFromFiltersBar(filters);
  const forceStatusFilterData=forceStatusFilter && forceStatusFilter.length > 0
  const { data, refetch, loading, error, fetchMore } = useQuery<{
    getCampaigns: {
      items: CampaignProps[];
      total: number;
      totalLocal?: number;
      totalNonLocalConflicted?: number;
      totalNotEligibleForSelfApproval?: number;
      totalInvalid?: number;
    };
  }>(campaignsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: vceCampaignsPage.getFilters({
          ...filters,
          [CampaignFilterType.Status]:
            forceStatusFilterData ? forceStatusFilter : filters.status,
        }),
        offerSource: OfferSource.VCE,
        order,
        limit: 24,
        offset: 0,
        bulkActionsCounters: Boolean(bulkAction),
        priorityFilters: priorityFilters,
      },
    },
  });
  const [bulkCampaignsStatusUpdate, bulkCampaignsStatusUpdateState] = useMutation<BulkCampaignMutationResult>(
    campaignsGqls.mutations.bulkCampaignsStatusUpdate,
    {
      refetchQueries: (result) => {
        return result?.data?.bulkCampaignsStatusUpdate?.isProcessed ? ['Campaigns', 'GetCampaignsQuickFilters'] : [];
      },
    },
  );
  const {
    getCampaigns: {
      items: campaigns,
      total,
      totalLocal,
      totalNonLocalConflicted,
      totalNotEligibleForSelfApproval,
      totalInvalid,
    },
  } = data || { getCampaigns: { items: [] as CampaignProps[] } };

  const { startPolling, stopPolling } = useQuery<{
    getCampaigns: {
      items: CampaignProps[];
      total: number;
      totalLocal?: number;
      totalNonLocalConflicted?: number;
      totalNotEligibleForSelfApproval?: number;
      totalInvalid?: number;
    };
  }>(campaignsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: vceCampaignsPage.getFilters({
          ...filters,
          [CampaignFilterType.Status]:
            forceStatusFilterData ? forceStatusFilter : filters.status,
        }),
        offerSource: OfferSource.VCE,
        order,
        limit: campaigns.length || 24,
        offset: 0,
        bulkActionsCounters: Boolean(bulkAction),
        priorityFilters: priorityFilters,
      },
    },
  });

  useToastError(error, 'Error loading campaigns');
  useQueryInterval(60_000, startPolling, stopPolling);

  const { config } = useSelector(marketConfig);
  const { user } = useSelector(users);
  const userRole = user.marketInfo.find(marketInfo => marketInfo.isDefault).role;
  const history = useHistory();
  const {
    dateFormat,
    timeFormat,
    startTimezone,
    endTimezone,
    enableCampaignSelfApproval,
    primaryLanguage,
    secondaryLanguage,
  } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, timeFormat, startTimezone, endTimezone };
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(mapOrder(order));
  const [campaignsSelection, setCampaignsSelection] = useState<CampaignsSelection>(null);
  const selectedCampaignsIds = new Set<number>(campaignsSelection?.selectedCampaignsIds ?? []);
  const excludedCampaignsIds = new Set<number>(campaignsSelection?.excludedCampaignsIds ?? []);
  const isEligibleForSelfApproval =
    enableCampaignSelfApproval && isInArray([UserRole.SysAdmin, UserRole.Admin], userRole);
  const startSSEConnection = () => {
    const handleCampaigns = (event: any) => {
      const data1: CampaignsUpdates = JSON.parse(event.data);
      setCampaignsUpdates(data1);
    };

    if (sseService.sse) {
      try {
        sseService.sse?.addEventListener(SSE.Campaigns, handleCampaigns);
      } catch (error) {
        console.error('Error adding event listener for Campaigns:', error);
      }
      return () => {
        try {
          sseService.sse?.removeEventListener(SSE.Campaigns, handleCampaigns);
        } catch (error) {
          console.error('Error removing event listener for Campaigns:', error);
        }
      };
    }
  };
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      vceCampaignsPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({
            ...res,
            [key]:
              Array.isArray(params[key]) || isInArray(['true', 'false'], params[key]) ? params[key] : [params[key]],
          }),
          {},
        ),
      ),
    );
  });

  const onActionClick = async (actionType: ActionType, campaign: CampaignProps) => {
    if (isInArray([ActionType.Edit, ActionType.View], actionType)) {
      const campaignData = await getCampaignById(campaign.id);
      store.dispatch(
        openModal({
          modal: Modals.CampaignModal,
          props: { mode: actionType, campaign: campaignData?.data?.getCampaign },
        }),
      );
    }
  };

  const drillDownToRedemptionsReport = (externalId: number) => {
    const startDate = new Date(getLastWeek().setHours(0, 0, 0)).getTime().toString();
    const endDate = new Date(new Date().setHours(0, 0, 0)).getTime().toString();
    const redemptionFilters = `dateRangeStart=${startDate}&dateRangeEnd=${endDate}&type=${AggregationType.overtime}&interval=${Interval.Day}&campaignExternalIds=${externalId}`;
    const url = `${tabToPathMap[tabName.Redemptions]}?${redemptionFilters}`;
    store.dispatch(resetRedemptions());
    store.dispatch(setIsInitialEntrance(true));
    store.dispatch(setFilter({ filter: [RedemptionFilterType.Type], value: AggregationType.overtime }));
    store.dispatch(setFilter({ filter: [RedemptionFilterType.Interval], value: Interval.Day }));
    history.push(url);
  };

  const onCreateNewCampaign = () => {
    store.dispatch(
      openModal({
        modal: Modals.CampaignModal,
        props: { mode: FormMode.New },
      }),
    );
  };

  const fetchNextCampaigns = async () => {
    if (campaigns?.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: vceCampaignsPage.getFilters({
              ...filters,
              [CampaignFilterType.Status]:
                forceStatusFilter && forceStatusFilter.length > 0 ? forceStatusFilter : filters.status,
            }),
            offerSource: OfferSource.VCE,
            offset: campaigns.length,
            limit: 16,
            order,
            bulkActionsCounters: Boolean(bulkAction),
            priorityFilters: priorityFilters,
          },
        },
      });
    }
  };

  const onSortModelChange = (sortModel: { [key: string]: OrderDirection }, gridSortModal: GridSortModel) => {
    if (!isEqual(sortModel, order)) {
      store.dispatch(vceCampaignsPage.actions.setOrder(sortModel));
      setGridSortModel(gridSortModal);
    }
  };

  const onBulkActionChange = (action: CampaignBulkAction) => {
    setBulkAction(action);
    setCampaignsSelection(
      action
        ? {
            virtualSelectAll: true,
            excludedCampaignsIds: [],
            selectedCampaignsIds: [],
          }
        : null,
    );
  };

  const onBulkActionSubmit = async (): Promise<FetchResult<BulkCampaignMutationResult>> => {
    return bulkCampaignsStatusUpdate({
      variables: {
        data: {
          campaignsSelection,
          status: capitalize(
            bulkAction === CampaignBulkAction.BulkSubmitForApproval
              ? ApprovalStatus.PendingApproval
              : ApprovalStatus.Approved,
          ),

          filters: vceCampaignsPage.getFilters({
            ...filters,
            [CampaignFilterType.Status]: forceStatusFilter,
          }),
          total,
        },
      },
    });
  };

  const getSelectAllState = () => ({
    checked:
      selectedCampaignsIds.size === total || (campaignsSelection?.virtualSelectAll && !excludedCampaignsIds.size),
    intermediate:
      (selectedCampaignsIds.size > 0 && selectedCampaignsIds.size !== total) ||
      (campaignsSelection?.virtualSelectAll && excludedCampaignsIds.size > 0),
  });

  const onSelectAll = () => {
    const selectAllState = getSelectAllState();
    const updatedVirtualSelectAll = !campaignsSelection.virtualSelectAll || selectAllState.intermediate;
    setCampaignsSelection({
      virtualSelectAll: updatedVirtualSelectAll,
      excludedCampaignsIds: [],
      selectedCampaignsIds: [],
    });
  };

  const onCampaignSelection = (campaignId: number) => {
    if (campaignsSelection.virtualSelectAll) {
      if (excludedCampaignsIds.has(campaignId)) {
        excludedCampaignsIds.delete(campaignId);
      } else {
        excludedCampaignsIds.add(campaignId);
      }
    } else if (selectedCampaignsIds.has(campaignId)) {
      selectedCampaignsIds.delete(campaignId);
    } else {
      selectedCampaignsIds.add(campaignId);
    }
    setCampaignsSelection({
      ...campaignsSelection,
      excludedCampaignsIds: [...excludedCampaignsIds],
      selectedCampaignsIds: [...selectedCampaignsIds],
    });
  };

  const getSelectedConflictedCampaigns = () => {
    const conflictedCampaigns = campaigns.filter(
      (campaign) => campaign.alert === CampaignAlert.Conflicted && !campaign.localSchedule,
    );
    return campaignsSelection.virtualSelectAll
      ? totalNonLocalConflicted - conflictedCampaigns.filter((campaign) => excludedCampaignsIds.has(campaign.id)).length
      : conflictedCampaigns.filter((campaign) => selectedCampaignsIds.has(campaign.id)).length;
  };

  const getSelectedNotEligibleForSelfApprovalCampaigns = () => {
    if (isEligibleForSelfApproval || bulkAction !== CampaignBulkAction.BulkApprove) {
      return 0;
    }

    const notEligibleForSelfApprovalCampaigns = campaigns.filter(
      (campaign) =>
        campaign.alert !== CampaignAlert.Conflicted &&
        !campaign.localSchedule &&
        (campaign.updatedBy.id === user.id || campaign.createdBy.id === user.id),
    );
    return campaignsSelection.virtualSelectAll
      ? totalNotEligibleForSelfApproval -
          notEligibleForSelfApprovalCampaigns.filter((campaign) => excludedCampaignsIds.has(campaign.id)).length
      : notEligibleForSelfApprovalCampaigns.filter((campaign) => selectedCampaignsIds.has(campaign.id)).length;
  };

  const getSelectedLocalCampaigns = () => {
    const localCampaigns = campaigns.filter((campaign) => campaign.localSchedule);
    return campaignsSelection.virtualSelectAll
      ? totalLocal - localCampaigns.filter((campaign) => excludedCampaignsIds.has(campaign.id)).length
      : localCampaigns.filter((campaign) => selectedCampaignsIds.has(campaign.id)).length;
  };

  const getSelectedInvalidCampaigns = () => {
    const invalidCampaigns = campaigns.filter(
      (campaign) =>
        campaign.alert !== CampaignAlert.Conflicted &&
        !campaign.localSchedule &&
        (isEligibleForSelfApproval || !(campaign.updatedBy.id === user.id || campaign.createdBy.id === user.id)) &&
        new Date(campaign.schedule.campaignEnd) < new Date(),
    );
    return campaignsSelection.virtualSelectAll
      ? totalInvalid - invalidCampaigns.filter((campaign) => excludedCampaignsIds.has(campaign.id)).length
      : invalidCampaigns.filter((campaign) => selectedCampaignsIds.has(campaign.id)).length;
  };

  const getTotalSelectedCampaigns = () =>
    campaignsSelection.virtualSelectAll
      ? total - campaignsSelection.excludedCampaignsIds.length
      : campaignsSelection.selectedCampaignsIds.length;

  const getCampaignsSelectionSummary = () => {
    const campaignSelectionSummary = { total: 0, local: 0, conflicted: 0, notEligibleForSelfApproval: 0, invalid: 0 };
    if (!campaignsSelection) {
      return campaignSelectionSummary;
    }

    campaignSelectionSummary.total = getTotalSelectedCampaigns();
    campaignSelectionSummary.local = getSelectedLocalCampaigns();
    campaignSelectionSummary.conflicted = getSelectedConflictedCampaigns();
    campaignSelectionSummary.notEligibleForSelfApproval = getSelectedNotEligibleForSelfApprovalCampaigns();
    campaignSelectionSummary.invalid = getSelectedInvalidCampaigns();
    return campaignSelectionSummary;
  };

  const getIsChecked = (campaignId: number): boolean =>
    (campaignsSelection.virtualSelectAll && !excludedCampaignsIds.has(campaignId)) ||
    selectedCampaignsIds.has(campaignId);

  const downloadCampaignData = async () => {
    setIsLoading(true);
    const rows = generateColumnNames(primaryLanguage, secondaryLanguage);
    const allFilteredCampaigns: CampaignReportProps[] = await getCampaignsForReport({
      filters: vceCampaignsPage.getFilters({
        ...filters,
        [CampaignFilterType.Status]:
          forceStatusFilter && forceStatusFilter.length > 0 ? forceStatusFilter : filters.status,
      }),
      order,
      limit: 9999,
      offset: 0,
      isBatchingRequired: true,
      bulkActionsCounters: Boolean(bulkAction),
      select: [
        'localSchedule',
        'externalId',
        'title',
        'type',
        'schedule',
        'status',
        'offerVersion',
        'push_notification',
        'id',
      ],
    });
    // Create an array of promises for generating campaign rows
    const campaignRowPromises = allFilteredCampaigns.map((campaign) =>
      generateCampaignRow(campaign, dateTimeConfig, config),
    );
    // Execute the promises in parallel
    const campaignRows = await Promise.all(campaignRowPromises);
    rows.push(...campaignRows);
    downloadCSV(rows, `CampaignData`);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);

  // Establishes SSE connection on campaigns dashboard
  useEffect(() => {
    const cleanup = startSSEConnection();
    return cleanup;
  }, []);

  useEffect(() => {
    refetch({
      data: {
        filters: vceCampaignsPage.getFilters({
          ...filters,
          [CampaignFilterType.Status]:
            forceStatusFilter && forceStatusFilter.length > 0 ? forceStatusFilter : filters.status,
        }),
        order,
        offset: 0,
        limit: campaigns.length || 24,
        bulkActionsCounters: Boolean(bulkAction),
        priorityFilters: priorityFilters,
      },
    });
  }, [campaignsUpdates]);

  return (
    <NewPageContainer>
      <NewStyledHeader data-automation-id="header">       
          <NewTextPageTitle text={tabName.Campaigns} />
          <TitleActionButtonsContainer data-automation-id="actions">
              <ActionButtonsContainer>
              <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                <CampaignBulkActions
                  bulkAction={bulkAction}
                  offerSource={OfferSource.VCE}
                  onBulkActionChange={onBulkActionChange}
                  onBulkActionSubmit={onBulkActionSubmit}
                  selectedCampaigns={getCampaignsSelectionSummary()}
                  bulkUpdateInProgress={bulkCampaignsStatusUpdateState.loading}
                />
              </RoleGuard>
              <NewStyledButton onClick={() => downloadCampaignData()} data-title="Download CSV">
                <NewStyledDownload name="newDownload" />
              </NewStyledButton>
              <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                {!bulkAction && <NewAddNewButton onClick={onCreateNewCampaign} />}
              </RoleGuard>
            </ActionButtonsContainer>
            <CampaignsFilterBar
              offerSource={OfferSource.VCE}
              filter={urlFilters.filter}
              filterMulti={urlFilters.filterMulti}
              filterWithClear={urlFilters.filterWithClear}
              forceStatusFilter={forceStatusFilter}
              onSortModelChange={onSortModelChange}
              />    
        </TitleActionButtonsContainer>
      </NewStyledHeader>
        <NewTableContainer>
          <Tooltip id={"campaign-lock-icon"} onHover content="Locked for changes" place="bottom" />
          {isLoading && (
            <CampaignLoaderWrapper>
              <Loader role="status" aria-label="Loading more campaigns" aria-busy={true} size={LoaderSize.Large}/>
            </CampaignLoaderWrapper>
          )}
          <NewTableHeader>
            <NewTableBorder/>
            <NewTableSubHeader>
            <QuickFilters filterWithClear={urlFilters.filterWithClear} />
            <StyledTotalAmountContainer>
              <NewStyledTotalAmountWithTable amount={total} prefixText="Total results of:" />
              {campaignsSelection && (
                <NewStyledTotalAmountWithTable amount={getTotalSelectedCampaigns()} prefixText="Selected:" />
              )}
            </StyledTotalAmountContainer>
            </NewTableSubHeader>
            <NewTableBorder/>
          </NewTableHeader>
          
          <NewStyledTable
            tableProps={{
              ...getCampaignsTableProps(
                onActionClick,
                dateTimeConfig,
                drillDownToRedemptionsReport,
                filters[CampaignFilterType.Priority] === 'true',
                bulkAction
                  ? {
                      getIsChecked,
                      getSelectAllState,
                      onCampaignSelection,
                      onSelectAll,
                    }
                  : null,
                OfferSource.VCE,
              ),
              rows: campaigns ?? [],
              disableSorting: filters[CampaignFilterType.Priority] === 'true',
            }}
            onRowOver={(e: React.SyntheticEvent<HTMLDivElement>) => showActionsCell(e.currentTarget.dataset.id)}
            onRowOut={(e: React.SyntheticEvent<HTMLDivElement>) => hideActionsCell(e.currentTarget.dataset.id)}
            onRowClick={(gridRowParams: any) => onActionClick(ActionType.View, gridRowParams)}
            onRowsScrollEnd={fetchNextCampaigns}
            onSortModelChange={filters[CampaignFilterType.Priority] === 'true' ? undefined : onSortModelChange}
            gridSortModel={gridSortModel}
          />
          {(loading || bulkCampaignsStatusUpdateState.loading) && (
             <StyledLoaderContainerCampaign isEmpty={!campaigns?.length}>
                <StyledLoader
                size={LoaderSize.Medium}
                role="status"
                aria-busy={true}
                />
           </StyledLoaderContainerCampaign>
          )}
        </NewTableContainer>
    </NewPageContainer>
  );
};

export default Campaigns;
