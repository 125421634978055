import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { Tag } from 'utils/types/tags';
import { ImageFilterType, ImageProps, ImageSource } from 'pages/settings/images/Images.consts';
import { ArchiveStatus } from 'pages/settings/settings.const';
import type { RootState } from 'app/store';
import { buildBaseAndFilters, generateSearchFilter } from 'utils/serverFilters';
import { cloneDeep } from 'lodash';

export enum ImagesStateId {
  VceImagesPage = 'vceImagesPage',
  DoeImagesPage = 'doeImagesPage',
  ImagesSelection = 'imagesSelection',
}

export interface ImagesFilters {
  [ImageFilterType.Archive]: ArchiveStatus[];
  [ImageFilterType.Tags]: Tag[];
  [ImageFilterType.SearchQuery]: string;
}

type ImagesGenericSlice = {
  imagesState: (state: RootState) => any;
  reducer: any;
  actions: any;
  getFilters: any;
};

export interface Images {
  filters: ImagesFilters;
  selectedImage: ImageProps;
}

export const initialState: Images = {
  filters: {
    [ImageFilterType.Archive]: [ArchiveStatus.Unarchived],
    [ImageFilterType.Tags]: [] as Tag[],
    [ImageFilterType.SearchQuery]: '',
  },
  selectedImage: null,
};

export const createImagesGenericSlice = (sliceName: ImagesStateId): ImagesGenericSlice => {
  const getFilters = (initialFilters: ImagesFilters): any => {
    const ImageServerFiltersRecord = {
      [ImageFilterType.Tags]: { serverFilter: 'Tag_id', operation: 'in' },
      [ImageFilterType.Id]: { serverFilter: 'id', operation: 'in' },
    };

    const filters = buildBaseAndFilters(ImageServerFiltersRecord, initialFilters);

    if (initialFilters[ImageFilterType.Archive]?.length === 1) {
      filters.AND.push({
        isArchive: {
          is: initialFilters[ImageFilterType.Archive][0] === ArchiveStatus.Archived,
        },
      });
    }

    if (initialFilters[ImageFilterType.SearchQuery]?.length) {
      const searchByFields = ['name', 'description','fileName'];
      const idFields = ['id'];
      filters.AND.push(generateSearchFilter(searchByFields, idFields, initialFilters[ImageFilterType.SearchQuery]));
    }

    return filters;
  };

  const imagesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
      setFilters(state, action) {
        state.filters = action.payload;
      },
      setSelectedImage(state, action) {
        state.selectedImage = action.payload;
      },
    },
  });

  const imagesState = (state: RootState) => state.images[sliceName];
  return {
    imagesState,
    reducer: imagesSlice.reducer,
    actions: imagesSlice.actions,
    getFilters,
  };
};

export const vceImagesPage: ImagesGenericSlice = createImagesGenericSlice(ImagesStateId.VceImagesPage);
export const doeImagesPage: ImagesGenericSlice = createImagesGenericSlice(ImagesStateId.DoeImagesPage);
export const imagesSelection: ImagesGenericSlice = createImagesGenericSlice(ImagesStateId.ImagesSelection);

const imagesReducer = combineReducers({
  [ImagesStateId.VceImagesPage]: vceImagesPage.reducer,
  [ImagesStateId.DoeImagesPage]: doeImagesPage.reducer,
  [ImagesStateId.ImagesSelection]: imagesSelection.reducer,
});

export default imagesReducer;
