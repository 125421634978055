import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isInArray } from 'utils/array';
import { AuthType, User, UserRole } from 'utils/types/users';
import type { RootState } from '../store';
import { usersGqlsDefault } from './users.gqls';
import { client } from 'app/apollo';
import { md, pki, util } from 'node-forge';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';

export interface UsersState {
  user: User;
}

export interface Action {
  payload: {
    user: User;
  };
}

const getDefaultUser = () => {
  // The Fetch Request that connects to resolver and grabs our env variables
  const fetchEnvVar = async () => {
    return client.query({ query: usersGqlsDefault.queries.getFirstLastEmail, fetchPolicy: 'no-cache' });
  };

  const data = fetchEnvVar();

  return data.then((res) => {
    //Checks if theres data
    if (res.data && res.data?.getEnvironments) {
      return {
        id: 1,
        firstName: res.data.getEnvironments.firstName,
        lastName: res.data.getEnvironments.lastName,
        company: 'Other',
        authType: AuthType.VCE,
        showDefaultMarketModel: true,
        email: res.data.getEnvironments.adminEmail,
        marketInfo: [{
          marketId: 1,
          userId: '1',
          role: "admin",
          isDefault: true,
          isActive: true,
          marketName: 'US',
          marketFullName: 'United States',
          notifications: []
        },
        {
          marketId: 3,
          userId: '1',
          role: "admin",
          isDefault: false,
          isActive: true,
          marketName: 'AU',
          marketFullName: 'Australia',
          notifications: []
        },
        {
          marketId: 5,
          userId: '1',
          role: "admin",
          isDefault: false,
          isActive: true,
          marketName: 'DE',
          marketFullName: 'Germany',
          notifications: []
        }]
      };
    }
    return {};
  });
};

const verifyUserResponse = (data: string, signature: any) => {
  const publicKey = pki.publicKeyFromPem(signature.rsaPublicKey);

  const digest = md.sha256.create();
  digest.update(data, 'utf8');

  const decodedSignature = util.decode64(signature.hash);

  return publicKey.verify(digest.digest().bytes(), decodedSignature);
};

export const initUser = createAsyncThunk('users/init', async (payload, thunkAPI) => {
  const headers = new Headers({
    'Cache-Control': 'no-store',
  });
  if (!isInArray(['development'], process.env.NODE_ENV)) {
    const res = await fetch('/user', { credentials: 'include', cache: 'no-store', headers: headers });
    if (!res.ok) {
      return thunkAPI.rejectWithValue(null);
    }
    const data = await res.json();
    const { user, signature } = data;
    if (!verifyUserResponse(JSON.stringify(user), signature)) {
      console.error('Data has been tampered with!');
      return thunkAPI.rejectWithValue('Data integrity failed');
    }
    return user;
  }
  return getDefaultUser();
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: undefined,
  } as UsersState,
  reducers: {
    logout(state) {
      state.user = undefined;
    },
    updateUserDetails(state, action) {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initUser.pending, (state) => {
      return state;
    });
    builder.addCase(initUser.fulfilled, (state, action) => {
      state.user = action.payload as User;
      const marketDetail = state.user.marketInfo.find((market) => market.isDefault === true);
      if (state.user.showDefaultMarketModel === true) {
        showToast(MessageType.Info, `Default market has been set to ${marketDetail.marketName} market.`);
      }
      return state;
    });

    builder.addCase(initUser.rejected, () => {
      return null;
    });
  },
});

export const users = (state: RootState) => state.users;
export const { logout, updateUserDetails } = usersSlice.actions;

export default usersSlice.reducer;
